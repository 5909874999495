import React from 'react';
import styled from '@emotion/styled';
import NavLink from './NavLink';

import respond from '../../styles/mediaQueries';

const Nav = styled('nav')`
    position: relative;
	width: 100%;
	height: 2.8rem;
    display: flex;
	justify-content: flex-end;
	color: ${props => props.color};

	${respond('bp4')} {
		display: none;
	}

    & ul {
        display: flex;
        margin-right: 6.4rem;
        align-items: center;
		margin-bottom: 0;
		list-style-type: none;
		color: inherit;
	}

	& li {
		color: inherit;
	}
	


    & li:not(:first-of-type) {
        margin-left: 3.2rem;
    }

    &::after {
		content: '';
		display: block;
		width: 100%;
		height: .3rem;
		background-color: ${props => props.color};
		position: absolute;
		bottom: -.7rem;
		right: 0;
	}

`;

const Navbar = ({ color }) => {
	return (
		<Nav color={color}>
			<ul>
				<NavLink to='/'>Home</NavLink>
				<NavLink to='/work/'>Work</NavLink>
				<NavLink to='/about/'>About</NavLink>
				<NavLink to='/contact/'>Contact</NavLink>
			</ul>
		</Nav>
	);
};

export default Navbar;
