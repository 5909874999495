import { css } from '@emotion/core';

const global = css`
	body {
		overflow-y: scroll;
		color: var(--text);
	}

	ul {
		list-style-type: none;
	}
`;

export default global;
