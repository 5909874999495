import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';
import favicon from '../../images/favicon/favicon.ico';

const SEO = ({ title, description }) => {
	const { pathname } = useLocation();
	const { site } = useStaticQuery(query);

	const {
		defaultTitle,
		titleTemplate,
		defaultDescription,
		siteUrl
	} = site.siteMetadata;

	const seo = {
		title: title || defaultTitle,
		description: description || defaultDescription,
		url: `${siteUrl}${pathname}`
	};

	return (
		<Helmet title={seo.title} titleTemplate={titleTemplate}>
			<meta name='description' content={seo.description} />
			{seo.url && <meta property='og:url' content={seo.url} />}
			{seo.title && <meta property='og:title' content={seo.title} />}
			{seo.description && (
				<meta property='og:description' content={seo.description} />
			)}
			<link rel='icon' href={favicon} />
		</Helmet>
	);
};

const query = graphql`
	query SEO {
		site {
			siteMetadata {
				defaultTitle: title
				titleTemplate
				defaultDescription: description
				siteUrl: url
			}
		}
	}
`;

SEO.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string
};

SEO.defaultProps = {
	title: null,
	description: null
};

export default SEO;
