import React from 'react';
import styled from '@emotion/styled';

import respond from '../../styles/mediaQueries';

const Container = styled('button')`
    display: none;
    width: 4.8rem;
    height: 4rem;  
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 250;
    overflow: hidden;
    background-color: transparent;
    border: none;
    outline: none;

    ${respond('bp4')} {
        display: flex;
    }
`;

const StyledIcon = styled('div')`
    height: 2.4rem;
    width: 3.2rem;
    position: relative;
    justify-content: ${props =>
		props.isOpen === true ? 'center' : 'space-between'};

    & span {
        display: block;
        position: absolute;
        top: 1.1rem;
        right: 0;
        left: 0;
        height: .2rem;
        width: 100%;
        background-color: ${props =>
			props.isOpen === true ? 'var(--textInverse)' : 'var(--text)'};
        transition: transform .2s ease-in-out;
    }

    & span:first-of-type {
        transform: ${props =>
			props.isOpen === true
				? 'rotate(45deg)'
				: 'rotate(0) translateY(-1rem)'};
    }

    & span:nth-of-type(2) {
        opacity: ${props => (props.isOpen === true ? '0' : '1')};
    }


    & span:last-of-type {
        transform: ${props =>
			props.isOpen === true
				? 'rotate(-45deg)'
				: 'rotate(0) translateY(1rem)'};
    }
`;

const MobileNavIcon = ({ isOpen, handleClick }) => {
	return (
		<Container
			role='button'
			aria-label='mobile navigation'
			tabIndex={0}
			onClick={handleClick}
			onKeyDown={handleClick}>
			<StyledIcon isOpen={isOpen}>
				<span />
				<span />
				<span />
			</StyledIcon>
		</Container>
	);
};

export default MobileNavIcon;
