import React from 'react';
import styled from '@emotion/styled';

import NavLink from './NavLink';

import respond from '../../styles/mediaQueries';

const StyledNav = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .15s ease-out;
    height: 100%;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    position: fixed;
    top: 1.2rem;
    left: 0;
    padding: 1.2rem;
    background-color: var(--bgPrimary);
    z-index: 200;
   

    & ul {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background-color: var(--bgSecondary);
        color: var(--textInverse);
    }

    ${respond('bp4')} {
        visibility: ${props => (props.isOpen === true ? 'visible' : 'hidden')};
        opacity: ${props => (props.isOpen === true ? 1 : 0)};
    }

    & li {
        margin-bottom: 4rem;
    }

`;

const MobileNav = ({ isOpen, handleClick }) => {
	return (
		<StyledNav isOpen={isOpen}>
			<ul>
				<NavLink to='/' onClick={handleClick}>
					Home
				</NavLink>
				<NavLink to='/work' onClick={handleClick}>
					Work
				</NavLink>
				<NavLink to='/about' onClick={handleClick}>
					About
				</NavLink>
				<NavLink to='/contact' onClick={handleClick}>
					Contact
				</NavLink>
			</ul>
		</StyledNav>
	);
};

export default MobileNav;
