import React from 'react';
import styled from '@emotion/styled';
import { ThemeToggler } from 'gatsby-plugin-dark-mode';

const StyledThemeToggle = styled('div')` 
    width: 4rem;
    height: 4rem;
    cursor: pointer;
	outline: none;
    color: var(--textInverse);
    background-color: transparent;
    border: none;
    padding: .8rem;

    & svg {
        width: 2.4rem;
        height: 2.4rem;
    }


`;

const ThemeToggle = ({ color }) => {
	const moonIcon = (
		<svg
			viewBox='0 0 16 16'
			xmlns='http://www.w3.org/2000/svg'
			fillRule='evenodd'
			clipRule='evenodd'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeMiterlimit='1.5'>
			<path
				d='M8.545 2.021C3.162 2.249 1.307 8 4.063 11.415c2.068 2.563 6.619 3.329 9.304-.442.072-.102-3.325.188-4.988-2.861-1.95-3.574.281-6.096.166-6.091z'
				fill='none'
				stroke={color}
				strokeWidth='1.5'
			/>
		</svg>
	);

	const sunIcon = (
		<svg
			viewBox='0 0 16 16'
			xmlns='http://www.w3.org/2000/svg'
			fillRule='evenodd'
			clipRule='evenodd'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeMiterlimit='1.5'>
			<g fill='none' stroke={color} strokeWidth='1.3'>
				<circle cx='7.961' cy='7.985' r='3.805' />
				<path d='M7.878 4.18V1M11.766 7.985h3.171M1 7.985h3.155M7.878 14.97v-3.18M5.27 10.675l-2.72 2.719M13.281 2.605l-2.66 2.66M10.659 10.667l2.727 2.727M2.507 2.605l2.727 2.727' />
			</g>
		</svg>
	);

	return (
		<ThemeToggler>
			{({ theme, toggleTheme }) => (
				<StyledThemeToggle
					aria-label='theme toggle'
					role='button'
					tabIndex={0}
					onClick={() =>
						toggleTheme(theme === 'dark' ? 'light' : 'dark')}>
					{theme === 'dark' ? sunIcon : moonIcon}
				</StyledThemeToggle>
			)}
		</ThemeToggler>
	);
};

export default ThemeToggle;
