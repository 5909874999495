import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';

import respond from '../../styles/mediaQueries';

const StyledLogo = styled(Link)`
    height: 4.4rem;
	margin-right: 2.4rem;


	${respond('bp4')} {
		height: 4rem;
		margin-right: 0rem;
	}

	& svg {
		height: 100%;
		width: auto;
	}
`;

const LogoContainer = ({ to, children }) => {
	return <StyledLogo to={to}>{children}</StyledLogo>;
};

export default LogoContainer;
