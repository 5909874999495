import React, { useState, useEffect } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import styled from '@emotion/styled';

import respond from '../../styles/mediaQueries';

const NavLinkGroup = styled('li')`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
	position: relative;

    & svg {
        display: block;
        position: absolute;
        bottom: -1.3rem;
        left: 50%;
        transform:  translateX(-50%);
        width: 2.4rem;
		height: .8rem;

		${respond('bp2')} {
			bottom: -1.5rem;
		}
		
		${respond('bp4')} {
			bottom: auto;
			transform: rotate(-90deg) translateX(0);
			left: -2.4rem;
			width: 1.6rem;

			& path {
				fill: var(--textInverse);
			}
		}
    }

    & a {
		color: inherit;
		text-decoration: none;
	
		
		&:hover,
		&:active,
		&:visited {
			text-decoration: underline;
		}
	}
`;

const Arrow = styled('svg')`
	${respond('bp4')} {
		display: none;
	}
`;

const StyledLink = styled(GatsbyLink)`
    color: var(--link);
    position: relative;
`;

const NavLink = props => {
	const [ isCurrent, setCurrent ] = useState(false);
	const path = typeof window !== 'undefined' ? window.location.pathname : '';

	useEffect(
		() => {
			if (path === props.to) {
				setCurrent(true);
			}
		},
		[ path, props.to ]
	);

	const arrow = (
		<Arrow
			viewBox='0 0 24 8'
			xmlns='http://www.w3.org/2000/svg'
			fillRule='evenodd'
			clipRule='evenodd'
			strokeLinejoin='round'
			strokeMiterlimit='2'>
			<path d='M11.999 8L24 0H0l11.999 8z' fill={'var(--text)'} />
		</Arrow>
	);

	return (
		<NavLinkGroup>
			<StyledLink {...props}>{props.children}</StyledLink>
			{isCurrent && arrow}
		</NavLinkGroup>
	);
};

export default NavLink;
