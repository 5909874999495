import React from 'react';
import styled from '@emotion/styled';

import Logo from '../svg/Logo';
import Navbar from './Navbar';
import LogoContainer from './LogoContainer';

import respond from '../../styles/mediaQueries';

const StyledFooter = styled('footer')`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 30.4rem;
    padding: 2.4rem;
    background-color: var(--bgSecondary);
	color: var(--textInverse);
	border-radius: .2rem;
	
	${respond('bp4')} {
		height: 24rem;
		margin: 1.2rem;
	}
`;

const Links = styled('div')`
    display: flex;
	justify-content: space-between;
	align-items: center;
	
	${respond('bp4')} {
		justify-content: center;
	}
`;

const Copyright = styled('div')`
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
	font-family: var(--fontHeader);
	color: var(--textInverse2);

    & p {
        text-align: right;
        line-height: 1.2;
		margin-bottom: 0;
		
		${respond('bp4')} {
		    text-align: center;
		}
	}
	
	${respond('bp4')} {
		justify-content: center;
	}
`;

const Footer = () => {
	return (
		<StyledFooter>
			<Links>
				<LogoContainer to='/'>
					<Logo fill={'var(--textInverse)'} />
				</LogoContainer>
				<Navbar color={'var(--textInverse)'} />
			</Links>

			<Copyright>
				<div>
					<p>
						<small>Copyright {new Date().getFullYear()}</small>
					</p>
					<p>
						<small>Brandon Corey Web Development</small>
					</p>
					<p>
						<small>Designed & Built in KCMO</small>
					</p>
				</div>
			</Copyright>
		</StyledFooter>
	);
};

export default Footer;
