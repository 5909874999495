import React from 'react';
import { Global } from '@emotion/core';
import styled from '@emotion/styled';

import Header from './Header';
import Footer from './Footer';

import { reset, global, fonts, typography } from '../../styles';

import respond from '../../styles/mediaQueries';

const Wrapper = styled('div')`
 	background-color: var(--pageBackground); 
`;

const Site = styled('div')`
	display: flex;
	flex-direction: column;
	max-width: 140rem;
	min-height: 100vh;
	margin: auto;
	padding: 3.2rem;
	background-color: var(--bgPrimary);
	box-shadow: 0 .4rem 2rem hsla(0, 0%, 0%, .2);

	${respond('bp1')} {
		padding: 2.4rem;
	}

	${respond('bp4')} {
		padding: 0;
	}
`;

const Main = styled('main')`
	flex-grow: 1;
	margin-top: 8rem;

	${respond('bp4')} {
		padding: 0 1.2rem;
		margin-top: 6.4rem;
	}
`;

const Layout = ({ children, location }) => {
	return (
		<Wrapper>
			<Site>
				<Global styles={fonts} />
				<Global styles={reset} />
				<Global styles={global} />
				<Global styles={typography} />
				<Header />

				<Main>{children}</Main>

				<Footer />
			</Site>
		</Wrapper>
	);
};

export default Layout;
