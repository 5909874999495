import React, { useState } from 'react';
import styled from '@emotion/styled';

import Navbar from './Navbar';
import LogoContainer from './LogoContainer';
import MobileNav from './MobileNav';
import MobileNavIcon from './MobileNavIcon';
import Logo from '../svg/Logo';
import ThemeToggle from './ThemeToggle';

import respond from '../../styles/mediaQueries';

const StyledHeader = styled('header')`
    display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	background-color: var(--bgPrimary);

	${respond('bp4')} {
		position: fixed;
		z-index: 150;
		width: 100%;
		top: 0;
		left: 0;
		padding: 1.2rem;
	}

`;

const ThemeToggleContainer = styled(`div`)`
	position: absolute;
	right: 0rem;
	top: 0rem;

	${respond('bp4')} {
		right: 7.2rem;
		top: 1.3rem;
	}
`;

const Header = ({ isDark, toggleDark }) => {
	const [ mobileMenuOpen, setMobileMenuOpen ] = useState(false);
	const toggleMobileNav = () => {
		setMobileMenuOpen(!mobileMenuOpen);
	};
	return (
		<StyledHeader mobileMenuOpen={mobileMenuOpen}>
			<LogoContainer to='/'>
				<Logo fill={'var(--text)'} />
			</LogoContainer>
			<Navbar color={'var(--text)'} id='header-nav' />
			<ThemeToggleContainer>
				<ThemeToggle color={'var(--text)'} />
			</ThemeToggleContainer>
			<MobileNavIcon
				isOpen={mobileMenuOpen}
				handleClick={toggleMobileNav}
			/>
			<MobileNav isOpen={mobileMenuOpen} handleClick={toggleMobileNav} />
		</StyledHeader>
	);
};

export default Header;
