const { css } = require('@emotion/core');
const { fontSize, lineHeight, margin } = require('../utils/typography');
const respond = require('../styles/mediaQueries');

const baseFS = 19;
const typeScaleInterval = 1.1;
const baseLH = 1.5;
const headerLH = 1.1;

const sizes = fontSize(baseFS, typeScaleInterval);
const baseline = lineHeight(sizes.base, baseLH);

const typography = css`
	body {
		font-size: ${sizes.base};
		line-height: ${lineHeight(sizes.base, baseLH)};
		font-family: 'Ivar', 'serif';
		font-weight: 400;
	}

	h1 {
		font-size: ${sizes.sd14};
		line-height: ${lineHeight(sizes.sd14, headerLH)};
		margin-bottom: ${margin(baseline, 0.75)};

		${respond('bp1')} {
			font-size: ${sizes.sd13};
			line-height: ${lineHeight(sizes.sd13, headerLH)};
		}

		${respond('bp2')} {
			font-size: ${sizes.sd12};
			line-height: ${lineHeight(sizes.sd12, headerLH)};
			letter-spacing: 0;
		}

		${respond('bp3')} {
			font-size: ${sizes.sd11};
			line-height: ${lineHeight(sizes.sd11, headerLH)};
		}

		${respond('bp4')} {
			font-size: ${sizes.sd8};
			line-height: ${lineHeight(sizes.sd8, headerLH)};
			margin-bottom: ${margin(baseline, 0.75)};
		}
	}

	h2 {
		font-size: ${sizes.sd11};
		line-height: ${lineHeight(sizes.sd11, headerLH)};
		margin-bottom: ${margin(baseline, 0.75)};

		${respond('bp1')} {
			font-size: ${sizes.sd11};
			line-height: ${lineHeight(sizes.sd11, headerLH)};
		}

		${respond('bp2')} {
			font-size: ${sizes.sd10};
			line-height: ${lineHeight(sizes.sd10, headerLH)};
			letter-spacing: 0;
		}

		${respond('bp3')} {
			font-size: ${sizes.sd9};
			line-height: ${lineHeight(sizes.sd9, headerLH)};
			letter-spacing: 0;
		}

		${respond('bp4')} {
			font-size: ${sizes.sd7};
			line-height: ${lineHeight(sizes.sd7, headerLH)};
			margin-bottom: ${margin(baseline, 0.65)};
		}
	}

	h3 {
		font-size: ${sizes.sd8};
		line-height: ${lineHeight(sizes.sd8, headerLH)};
		margin-bottom: ${margin(baseline, 0.65)};

		${respond('bp2')} {
			font-size: ${sizes.sd6};
			line-height: ${lineHeight(sizes.sd6, headerLH)};
		}

		${respond('bp4')} {
			font-size: ${sizes.sd5};
			line-height: ${lineHeight(sizes.sd5, headerLH)};
		}
	}

	h4 {
		font-size: ${sizes.sd6};
		line-height: ${lineHeight(sizes.sd6, headerLH)};
		margin-bottom: ${margin(baseline, 0.25)};

		${respond('bp2')} {
			font-size: ${sizes.sd4};
			line-height: ${lineHeight(sizes.sd4, headerLH)};
		}

		${respond('bp3')} {
			font-size: ${sizes.sd3};
			line-height: ${lineHeight(sizes.sd3, headerLH, 8)};
		}
	}

	h5 {
		font-size: ${sizes.sd3};
		line-height: ${lineHeight(sizes.sd3, headerLH + 0.1)};
		margin-bottom: ${margin(baseline, 0.25)};

		${respond('bp2')} {
			font-size: ${sizes.sd2};
			line-height: ${lineHeight(sizes.sd2, headerLH)};
		}
		${respond('bp5')} {
			font-size: ${sizes.sd2};
			line-height: ${lineHeight(sizes.sd2, headerLH)};
		}
	}

	h6 {
		font-size: ${sizes.sd1};
		line-height: ${lineHeight(sizes.sd1, headerLH + 0.1)};
		margin-bottom: ${margin(baseline, 0)};

		${respond('bp4')} {
			font-size: ${sizes.base};
			line-height: ${lineHeight(sizes.base, headerLH)};
		}
	}

	a {
		font-weight: 500;

		&:hover {
			text-decoration: underline;
		}

		&,
		&:active,
		&:visited {
			text-decoration: none;
		}
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	a {
		font-family: 'Soehne', "Roboto", "Helvetica", "Arial", sans-serif;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-weight: 600;
	}

	p {
		margin-bottom: ${margin(baseline, 1)};
	}

	small {
		font-size: ${sizes.small2};
	}

	ul {
		margin-bottom: ${margin(baseline, 1)};
		list-style-type: disc;
	}

	input,
	textarea {
		font-size: inherit;
	}
`;

module.exports = { typography, sizes };
