const respond = size => {
	const sizes = {
		bp1: '80em', //1280px
		bp2: '64em', //1024px
		bp3: '59.375em', //950px
		bp4: '43.75em', //700px
		bp5: '28.125em' //450px
	};

	return `@media only screen and (max-width: ${sizes[size]})`;
};

module.exports = respond;
