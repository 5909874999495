import { css } from '@emotion/core';
import IvarRegularWoff from '../fonts/IvarText-Regular.woff';
import IvarRegularWoff2 from '../fonts/IvarText-Regular.woff2';
import SoehneMediumWoff from '../fonts/soehne-web-kraftig.woff';
import SoehneMediumWoff2 from '../fonts/soehne-web-kraftig.woff2';
import SoehneBoldWoff from '../fonts/soehne-web-halbfett.woff';
import SoehneBoldWoff2 from '../fonts/soehne-web-halbfett.woff2';

const fonts = css`
	@font-face {
		font-family: 'Soehne';
		src: url(${SoehneMediumWoff2}) format('woff2'),
			url(${SoehneMediumWoff}) format('woff');
		font-weight: 500;
		font-style: normal;
		font-display: swap;
	}

	@font-face {
		font-family: 'Soehne';
		src: url(${SoehneBoldWoff2}) format('woff2'),
			url(${SoehneBoldWoff}) format('woff');
		font-weight: 600;
		font-style: normal;
		font-display: swap;
	}

	@font-face {
		font-family: 'Ivar';
		src: url(${IvarRegularWoff2}) format('woff2'),
			url(${IvarRegularWoff}) format('woff');
		font-weight: 400;
		font-style: normal;
		font-display: swap;
	}
`;

export default fonts;
