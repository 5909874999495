import { css } from '@emotion/core';

const reset = css`
	*,
	*::before,
	*::after {
		margin: 0;
		padding: 0;
		box-sizing: inherit;
	}

	html,
	body {
		height: 100%;
	}

	html {
		font-size: 62.5%; /* 1rem = 10px*/
		box-sizing: border-box;
	}
`;

export default reset;
