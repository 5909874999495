// Convert pixels to rem
const rem = pixels => {
	const split = pixels.toString().split('');
	split.splice(split.length - 1, 0, '.');
	split.splice(split.length, 0, 'rem');

	const joined = split.join('');
	return joined;
};

// Convert rem to pixels
const pixels = rem => {
	let str = rem;

	str = str.replace(/['rem.']/g, '');
	return parseFloat(str);
};

// Round to nearest 4px for 8pt grid
const roundTo4 = size => {
	const remainder = size % 4;
	const rounded = size - remainder;

	// round up if above 2.5
	if (remainder > 2.5) {
		return rounded + 4;
	}

	// rounded down
	return rounded;
};

// Build typescale based base font size and typographic scale ratio
const fontSize = (base, ratio) => {
	const sizes = [];

	let currentSize;
	currentSize = base;
	for (let i = 0; i <= 15; i++) {
		sizes.push(currentSize);
		currentSize = Math.round(currentSize * ratio);
	}

	const small = Math.round(base / ratio);
	const small2 = Math.round(small / ratio);

	// Return object w/ scale degrees
	return {
		sd15: rem(sizes[15]),
		sd14: rem(sizes[14]),
		sd13: rem(sizes[13]),
		sd12: rem(sizes[12]),
		sd11: rem(sizes[11]),
		sd10: rem(sizes[10]),
		sd9: rem(sizes[9]),
		sd8: rem(sizes[8]),
		sd7: rem(sizes[7]),
		sd6: rem(sizes[6]),
		sd5: rem(sizes[5]),
		sd4: rem(sizes[4]),
		sd3: rem(sizes[3]),
		sd2: rem(sizes[2]),
		sd1: rem(sizes[1]),
		base: rem(sizes[0]),
		small: rem(small),
		small2: rem(small2)
	};
};

// Calc line height and round to nearest 4px increment (8pt grid)
const lineHeight = (fontSize, scale, manualAdjust = 0) => {
	const initial = pixels(fontSize) * scale;
	const rounded = roundTo4(initial);

	return rem(rounded + manualAdjust);
};

// Calc margins as fraction or multiple of baseline grid unit
const margin = (baselineGridUnit, multiple = 1, manualAdjust = 0) => {
	const pixelSize = pixels(baselineGridUnit);
	const rounded = roundTo4(pixelSize * multiple);
	return rem(rounded + manualAdjust);
};

const headerMargin = (fontSize, manualAdjust = 0) => {
	const pixelSize = pixels(fontSize);
	const rawMargin = pixelSize * 0.5;
	const rounded = roundTo4(rawMargin);
	return rem(rounded + manualAdjust);
};

module.exports = {
	fontSize,
	lineHeight,
	margin,
	headerMargin
};
